import React, { Component } from "react";
import { Player, ControlBar, BigPlayButton } from "video-react";
import { Button } from "reactstrap";
import styled from "styled-components";
import "../../node_modules/video-react/dist/video-react.css";
import Amplify, { API } from "aws-amplify";

let sources = {
  video1: {},
  video2: {},
  video3: {},
  video4: {},
  video5: {}
};

const StyledDiv = styled.div`
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledDiv2 = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: left;
`;

async function fetchRandomVideo() {
  Amplify.configure({
    API: {
      endpoints: [
        {
          name: "videos",
          endpoint:
            "https://tur29g6vg3.execute-api.us-west-2.amazonaws.com/dev/",
          region: "us-west-2"
        }
      ]
    }
  });
  try {
    let myInit = {
      body: { action: "fetch-random" }
    };
    const data = await API.post("videos", "/videos", myInit);
    return data.body;
  } catch (e) {
    console.log(e);
  }
}

async function buildSources() {
  try {
    sources.video1 = await fetchRandomVideo();
    sources.video2 = await fetchRandomVideo();
    sources.video3 = await fetchRandomVideo();
    sources.video4 = await fetchRandomVideo();
    sources.video5 = await fetchRandomVideo();
  } catch (e) {
    console.log(e);
  }
}

export default class Browse extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      source: sources.video1.url,
      filename: sources.video1.filename,
      gt_class: sources.video1.gt_class,
      pred_class: sources.video1.pred_class,
      prob: sources.video1.pred_class_proba
    };

    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.load = this.load.bind(this);
    this.changeCurrentTime = this.changeCurrentTime.bind(this);
    this.seek = this.seek.bind(this);
    this.changePlaybackRateRate = this.changePlaybackRateRate.bind(this);
    this.changeVolume = this.changeVolume.bind(this);
    this.setMuted = this.setMuted.bind(this);
  }

  async componentDidMount() {
    await buildSources();
    // subscribe state change
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
  }

  setMuted(muted) {
    return () => {
      this.player.muted = muted;
    };
  }

  handleStateChange(state) {
    // copy player state to this component's state
    this.setState({
      player: state
    });
  }

  play() {
    this.player.play();
  }

  pause() {
    this.player.pause();
  }

  load() {
    this.player.load();
  }

  changeCurrentTime(seconds) {
    return () => {
      const { player } = this.player.getState();
      this.player.seek(player.currentTime + seconds);
    };
  }

  seek(seconds) {
    return () => {
      this.player.seek(seconds);
    };
  }

  changePlaybackRateRate(steps) {
    return () => {
      const { player } = this.player.getState();
      this.player.playbackRate = player.playbackRate + steps;
    };
  }

  changeVolume(steps) {
    return () => {
      const { player } = this.player.getState();
      this.player.volume = player.volume + steps;
    };
  }

  changeSource(name) {
    return () => {
      this.setState({
        source: sources[name].url,
        filename: sources[name].filename,
        gt_class: sources[name].gt_class,
        pred_class: sources[name].pred_class,
        prob: sources[name].pred_class_proba
      });
      this.player.load();
    };
  }

  render() {
    return (
      <StyledDiv>
        <h2 className="colorlib-heading">Browse Videos!</h2>
        <p>
          This page will randomly load five videos from our test set. Please
          refresh the page and click "Video 1" to get a fresh set of five
          videos!
        </p>
        <p>Warning: Some of the videos might be disturbing to some viewers!</p>
        <Player
          ref={player => {
            this.player = player;
          }}
          autoPlay={false}
          aspectRatio={"16:10"}
          setMuted={true}
        >
          <source src={this.state.source} />
          <BigPlayButton position="center" />
          <ControlBar autoHide={false} />
        </Player>
        <div className="pb-3">
          <Button onClick={this.changeSource("video1")} className="mr-3">
            Video 1
          </Button>
          <Button onClick={this.changeSource("video2")} className="mr-3">
            Video 2
          </Button>
          <Button onClick={this.changeSource("video3")} className="mr-3">
            Video 3
          </Button>
          <Button onClick={this.changeSource("video4")} className="mr-3">
            Video 4
          </Button>
          <Button onClick={this.changeSource("video5")} className="mr-3">
            Video 5
          </Button>
        </div>
        <StyledDiv2>
          <p>
            Classes: [0: No punch, 1: Violent Punch Contact, 2: Violent Punch No
            Contact]
          </p>
          <p>
            Classes: [3: Non-Violent Punch Contact, 4: Non-Violent Punch No
            Contact]
          </p>
        </StyledDiv2>
        <StyledDiv2>
          <p>Filename: {this.state.filename}</p>
          <p>GT Class: {this.state.gt_class}</p>
          <p>Pred Class: {this.state.pred_class}</p>
          <p>Prob: {this.state.prob}</p>
        </StyledDiv2>
      </StyledDiv>
    );
  }
}
