import React, { Component } from "react";
import styled from "styled-components";
import fistbump1 from "../images/fistbump1.gif";
import fistbump2 from "../images/fistbump2.gif";

const StyledDiv = styled.div`
  padding-top: 50px;
`;

const StyledImage = styled.img`
  height: 75%;
  width: 75%;
`;

const StyledDiv2 = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
`;

export default class About extends Component {
  render() {
    return (
      <StyledDiv>
        <section className="colorlib-about" data-section="about">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="row row-bottom-padded-sm animate-box"
                  data-animate-effect="fadeInLeft"
                >
                  <div className="col-md-12">
                    <div className="about-desc">
                      <h2 className="colorlib-heading">Fistbumps 🤜🤛</h2>
                      <p>
                        We found it really cool that our model was able to
                        recognize fistbumps as non-violent! Here are some
                        examples where our model was 99% sure they actions in
                        the video were not violent:
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="colorlib-about">
          <StyledDiv2>
            <StyledImage src={fistbump1} alt="jen-photo"></StyledImage>
            <p>
              Here is an adorable gif of a teacher welcoming a student with a
              fistbump
            </p>
          </StyledDiv2>
          <StyledDiv2>
            <StyledImage src={fistbump2} alt="jen-photo"></StyledImage>
            <p>Here is gif of President Obama fistbumping</p>
          </StyledDiv2>
        </section>
      </StyledDiv>
    );
  }
}
