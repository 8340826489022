import "./App.css";
import Routes from "./Routes";
import React, { Component } from "react";
import SideBar from "./components/Sidebar";
import Banner from "./components/Banner";

class App extends Component {
  render() {
    return (
      <div className="App container">
        <SideBar pageWrapId={"page-wrap"} outerContainerId={"App container"} />
        {/* <Banner></Banner> */}
        <Routes />
      </div>
    );
  }
}

export default App;
