export default {
  s3: {
    REGION: "us-east-1",
    BUCKET: "app-client"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://esvxulm2oe.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_z2SaEzbbB",
    APP_CLIENT_ID: "5c2ka2jdb7k1ockktrt09nlg5o",
    IDENTITY_POOL_ID: "us-east-1:a50fc519-d0dd-41cc-a0a3-4084204a1c96"
  }
};
