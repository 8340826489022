import React from "react";
import styled from "styled-components";
import banner from "../images/banner_watermark.jpg";

const StyledImage = styled.img`
  height: 100%;
  width: 100%;
  align-items: center;
`;

export default props => {
  return <StyledImage src={banner}></StyledImage>;
};
