import React, { Component } from "react";
import styled from "styled-components";
import "./Home.css";
import landing from "../images/landing.jpg";

const StyledImage = styled.img`
  height: 100%;
  width: 100%;
`;

export default class Home extends Component {
  render() {
    return (
      <div className="Home">
        <div className="lander">
          {/* <h1>Violence Detection</h1>
          <p>These violent delights have violent ends</p> */}
          <StyledImage src={landing} alt="jen-photo"></StyledImage>
        </div>
      </div>
    );
  }
}
