import React, { Component } from "react";
import styled from "styled-components";
import tsn1 from "../images/tsn1.png";
import tsn3 from "../images/tsn3.png";

const StyledDiv = styled.div`
  padding-top: 50px;
`;

const StyledImage = styled.img`
  height: 75%;
  width: 75%;
`;

const StyledDiv2 = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
`;

export default class How extends Component {
  render() {
    return (
      <StyledDiv>
        <section className="colorlib-about" data-section="about">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="row row-bottom-padded-sm animate-box"
                  data-animate-effect="fadeInLeft"
                >
                  <div className="col-md-12">
                    <div className="about-desc">
                      {/* <span className="heading-meta">About Us</span> */}
                      <h2 className="colorlib-heading">How it works!</h2>
                      <StyledDiv2>
                        <StyledImage src={tsn1} alt="jen-photo"></StyledImage>
                      </StyledDiv2>
                      <p>
                        For our heavy duty approach, we implemented the temporal
                        segment network or TSN for short. This model has a few
                        added benefits over some of the traditional action
                        recognition approaches. Temporal Segment Network is a
                        novel approach that addressed one of the key issues in
                        action recognition, which is capturing long range
                        dependencies for mapping out complex actions. This is
                        achieved by breaking videos down into equally sized
                        segments. Snippets are sparsely sampled across each
                        segment and funneled into a deep neural net. By running
                        predictions on sparsely sampled snippets, we not only
                        get the added benefit of capturing more complex actions
                        through modeling long range dependencies, but we also
                        get the added benefit of reduced computational costs,
                        which was a big plus for our tight capstone budget.
                      </p>
                      <p>
                        Before jumping into how the TSN works, I thought it
                        would be worthwhile to dig into the data inputs a little
                        bit. So the TSN model is a two stream approach, which
                        takes in two different modalities, the spatial
                        component, which are the RGB frames, and the temporal
                        component, which are the optical flows. The RGB frames
                        are used by the neural net to capture the spatial
                        features of the video, (so maybe it will pick up on the
                        fact that there are boxing gloves in the video) while
                        the optical flows are used to capture movement between
                        frames. The type of movement and speed may help the
                        model tease out differences between fist bumps and
                        punches. Optical flows are essentially a motion heatmap
                        calculated by measuring how pixel intensities shift from
                        frame to frame. This motion is captured in both the x
                        and y direction.
                      </p>
                      <StyledDiv2>
                        <StyledImage src={tsn3} alt="jen-photo"></StyledImage>
                      </StyledDiv2>
                      <p>
                        Now I am going to dig into the TSN model. We first set a
                        hyperparameter that defines how many segments we would
                        like to break a video up into. Frames are evenly
                        distributed across each segment. Then the model
                        uniformly samples snippets from each segment, pulling
                        out the RGB frames as well as the x and y optical flows.
                        These data are then funneled into a deep neural net. The
                        deep neural nets are separated by modality for each
                        segment, where the RGB frame is passed into a spatial 3D
                        neural net, and the optical flows are passed into a
                        temporal 2D deep neural net for the X and Y optical
                        flows. The original TSN paper used batch normalized
                        inception as the backbone for their deep neural net. For
                        our implementation, we are utilizing ResNet-50 as our
                        backbone. The outputs of the deep neural nets are passed
                        into a modality consensus head, which aggregates the
                        outputs for the spatial (RGB) pieces, and the temporal
                        (optical flow) pieces across all segments. The segment
                        consensus for each modality is combined and undergoes a
                        softmax to derive an overall class score. This is the
                        TSN in a nutshell. As you can imagine, any sort of video
                        analytics tool is computationally expensive. By sparsely
                        sampling snippets across each segment, the temporal
                        segment network is able to capture long range
                        dependencies, and strikes a nice balance between
                        accuracy and computational efficiency.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </StyledDiv>
    );
  }
}
