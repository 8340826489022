import React from "react";
import styled from "styled-components";
import { stack as Menu } from "react-burger-menu";

const StyledBurgerMenu = styled.div`
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  .bm-menu-wrap {
    left: 0;
    bottom: 0;
  }

  .bm-item:hover {
    color: white;
  }
`;

export default props => {
  return (
    <StyledBurgerMenu>
      <Menu {...props} noOverlay>
        <a className="menu-item" href="/">
          Home
        </a>

        {/* <a className="menu-item" href="/upload">
          Upload A Video
        </a> */}

        <a className="menu-item" href="/about">
          About Us
        </a>

        <a className="menu-item" href="/how">
          How It Works
        </a>

        <a className="menu-item" href="/fistbump">
          Fistbumps
        </a>

        <a className="menu-item" href="/browse">
          Browse Videos
        </a>

        {/* <a className="menu-item" href="/desserts">
        Desserts
      </a> */}
      </Menu>
    </StyledBurgerMenu>
  );
};
