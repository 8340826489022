import React, { Component } from "react";
import styled from "styled-components";
import jen from "../images/jen.png";
import alex from "../images/alex.png";
import lance from "../images/lance.png";
import ahsen from "../images/ahsen.png";

const StyledDiv = styled.div`
  padding-top: 50px;
`;

const StyledDiv2 = styled.div`
  padding-top: 20px;
`;

const StyledImage = styled.img`
  height: 260px;
  width: 260px;
`;

export default class About extends Component {
  render() {
    return (
      <StyledDiv>
        <section className="colorlib-about" data-section="about">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="row row-bottom-padded-sm animate-box"
                  data-animate-effect="fadeInLeft"
                >
                  <div className="col-md-12">
                    <div className="about-desc">
                      <h2 className="colorlib-heading">Who Are We?</h2>
                      <p>
                        We are a team of graduate students completing the data
                        science program through UC Berkeley’s School of
                        Information. In the fall semester of 2019 - for our
                        capstone project - we formed a team focused on
                        automatically detecting violence behavior in video
                        footage.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="colorlib-about">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div
                className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
                data-animate-effect="fadeInLeft"
              >
                <h2 className="colorlib-heading">Meet the ninjas!</h2>
              </div>
            </div>
            <div className="row row-pt-md">
              <div className="col-md-3 text-center animate-box">
                <div className="services color-1">
                  <span className="icon">
                    <i className="icon-bulb" />
                  </span>
                  <div className="desc">
                    <StyledImage src={jen} alt="jen-photo"></StyledImage>
                    <h3>Jen</h3>
                    <p>Lead Solutions Architect and PM</p>
                    <p>BS Materials Science & Eng @ Rice</p>
                    <p>Austin, TX</p>
                    <a href="https://www.linkedin.com/in/jendarrouzet/">
                      {" "}
                      LinkedIn{" "}
                    </a>
                    <a href="https://github.com/jendatx"> GitHub </a>
                  </div>
                </div>
              </div>
              <div className="col-md-3 text-center animate-box">
                <div className="services color-3">
                  <span className="icon">
                    <i className="icon-phone3" />
                  </span>
                  <div className="desc">
                    <StyledImage src={alex} alt="alex-photo"></StyledImage>
                    <h3>Alex</h3>
                    <p>Lead Data Engineer</p>
                    <p>BS Physics @ Oregon State</p>
                    <p>Cedar Rapids, IA</p>
                    <a href="https://www.linkedin.com/in/alex-dauenhauer/">
                      {" "}
                      LinkedIn{" "}
                    </a>
                    <a href="https://github.com/alexdauenhauer"> GitHub </a>
                  </div>
                </div>
              </div>
              <div className="col-md-3 text-center animate-box">
                <div className="services color-5">
                  <span className="icon">
                    <i className="icon-data" />
                  </span>
                  <div className="desc">
                    <StyledImage src={lance} alt="lance-photo"></StyledImage>
                    <h3>Lance</h3>
                    <p>Lead Model Developer</p>
                    <p>BS Neuroscience @ UC Santa Cruz</p>
                    <p>San Francisco, CA</p>
                    <a href="https://www.linkedin.com/in/lance-miles-b7618750/">
                      {" "}
                      LinkedIn{" "}
                    </a>
                    <a href="https://github.com/lmiles247"> GitHub </a>
                  </div>
                </div>
              </div>
              <div className="col-md-3 text-center animate-box">
                <div className="services color-5">
                  <span className="icon">
                    <i className="icon-data" />
                  </span>
                  <div className="desc">
                    <StyledImage src={ahsen} alt="ahsen-photo"></StyledImage>
                    <h3>Ahsen</h3>
                    <p>Lead Web Developer</p>
                    <p>BS ECE @ NC State</p>
                    <p>San Jose, CA</p>
                    <a href="https://www.linkedin.com/in/ahsen-qamar">
                      {" "}
                      LinkedIn{" "}
                    </a>
                    <a href="https://github.com/ahsenq"> GitHub </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="colorlib-about" data-section="about">
          <StyledDiv2>
            <div className="row">
              <div className="col-md-12">
                <div
                  className="row row-bottom-padded-sm animate-box"
                  data-animate-effect="fadeInLeft"
                >
                  <div className="col-md-12">
                    <div className="about-desc">
                      <p>
                        At the beginning of the project, all four ninjas had
                        completed our core data science classes and were keen to
                        apply our new skills on video data. We decided to focus
                        on violence detection, and augmented available video
                        datasets in this domain with videos available on
                        YouTube. We conducted a literature search in the area of
                        action recognition, and selected the{" "}
                        <a href="https://github.com/yjxiong/temporal-segment-networks">
                          Temporal Segment Network
                        </a>{" "}
                        approach. We were able to move further & faster with
                        transfer learning (starting from that project’s model
                        weights and fine-tuning by training on our purpose-built
                        video dataset). See more about How It Works.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </StyledDiv2>
        </section>
        <section className="colorlib-about" data-section="about">
          <StyledDiv2>
            <div className="row">
              <div className="col-md-12">
                <div
                  className="row row-bottom-padded-sm animate-box"
                  data-animate-effect="fadeInLeft"
                >
                  <div className="col-md-12">
                    <div className="about-desc">
                      <p>The Bittah-Ninjas would very much like to thank:</p>
                      <p>
                        <ol>
                          <li>
                            All of our instructors and peers at UC Berkeley,
                            including especially Capstone Advisors Joyce Shen
                            and David Steier
                          </li>
                          <li>
                            The authors of the original Temporal Segment Network
                            paper - Limin Wang, Yuanjun Xiong, Zhe Wang, Yu
                            Qiao, Dahua Lin, Xiaoou Tang, and Luc Van Gool
                          </li>
                          <li>
                            The superstars who build free and open-source
                            software like FFMPEG, Pandas, Python, Pytorch &
                            Node.js
                          </li>
                          <li>
                            Amazon & IBM for their generous student credits
                          </li>
                          <li>
                            The friends and family who supported our efforts!
                          </li>
                        </ol>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </StyledDiv2>
        </section>
      </StyledDiv>
    );
  }
}
