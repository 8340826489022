import React from "react";
import How from "./containers/How";
import Home from "./containers/Home";
import About from "./containers/About";
import Browse from "./containers/Browse";
import Upload from "./containers/Upload";
import Fistbump from "./containers/Fistbump";
import NotFound from "./containers/NotFound";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";

export default function Routes({ appProps }) {
  return (
    <Switch>
      <AppliedRoute path="/" exact component={Home} appProps={appProps} />
      {/* <AppliedRoute
        path="/upload"
        exact
        component={Upload}
        appProps={appProps}
      /> */}
      <AppliedRoute path="/about" exact component={About} appProps={appProps} />
      <AppliedRoute path="/how" exact component={How} appProps={appProps} />
      <AppliedRoute
        path="/browse"
        exact
        component={Browse}
        appProps={appProps}
      />
      <AppliedRoute
        path="/fistbump"
        exact
        component={Fistbump}
        appProps={appProps}
      />
      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  );
}
